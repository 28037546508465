<i18n>
{
  "en": {
    "bot-status": "Service Status",
    "bot-status-subtitle": "Check whether LiveCall for LINE WORKS is correctly set up and the service is active.",
    "loading-bot-status": "Loading LiveCall bot status…",
    "loading-bot-status-error": "Error loading LiveCall bot status!",
    "bot-status-not-configured": "LiveCall bot configuration is incomplete",
    "bot-upgrade-needed": "An upgrade to LINEWORKS API 2.0 is required. Please start the registration process again to upgrade.",
    "bot-status-configuration-error": "LiveCall bot is configured, but cannot communicate with LINE WORKS server",
    "bot-status-no-payment-method": "LiveCall bot is set up correctly, but is inactive due to a missing payment method",
    "bot-status-active": "LiveCall bot is active"
  },
  "ja": {
    "bot-status": "サービスのステータス",
    "bot-status-subtitle": "LiveCall for LINE WORKSが正しく設定され、サービスが有効化しているか確認できます。",
    "loading-bot-status": "LiveCall bot状態読み込み中",
    "loading-bot-status-error": "LiveCall bot状況の読み込みエラー",
    "bot-status-not-configured": "LiveCall botの設定が不完全",
    "bot-upgrade-needed": "LINEWORKS API 2.0の更新作業が必要です。再度登録手続きを行なってください。",
    "bot-status-configuration-error": "LiveCall botは設定されているが、LINE WORKSサーバーと通信できません",
    "bot-status-no-payment-method": "LiveCall botは正しく設定されていますが、お支払い方法が見つかりませんのために無効化されています",
    "bot-status-active": "LiveCall botは正常に稼働しています"
  }
}
</i18n>

<template>
  <v-card elevation="4">
    <v-card-title>{{ $t('bot-status') }}</v-card-title>
    <v-card-subtitle>{{ $t('bot-status-subtitle') }}</v-card-subtitle>
    <v-card-text>
      <p v-if="!botStatus">
        <v-progress-circular indeterminate width="2" size="12"></v-progress-circular>
        {{ $t('loading-bot-status') }}
      </p>
      <p v-else-if="botStatus === 'error'">
        <v-icon color="error">mdi-alert-octagon</v-icon>
        {{ $t('loading-bot-status-error') }}
      </p>
      <p v-else-if="botStatus === 'not-configured'">
        <v-icon color="warning">mdi-cog</v-icon>
        {{ $t('bot-status-not-configured') }}
      </p>
      <p v-else-if="botStatus === 'configuration-error'">
        <v-icon color="error">mdi-alert</v-icon>
        {{ $t('bot-status-configuration-error') }}
      </p>
      <p v-else-if="botStatus === 'upgrade-needed'">
        <v-icon color="warning">mdi-alert</v-icon>
        {{ $t('bot-upgrade-needed') }}
      </p>
      <p v-else-if="botStatus === 'no-payment-method'">
        <v-icon color="warning">mdi-credit-card</v-icon>
        {{ $t('bot-status-no-payment-method') }}
      </p>
      <p v-else-if="botStatus === 'active'">
        <v-icon color="success">mdi-check-decagram</v-icon>
        {{ $t('bot-status-active') }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BotStatusWidget',
  data: () => ({
    botStatus: undefined
  }),
  async mounted() {
    /** @type {Response} */
    const response = await this.$api.fetch('/status');

    if (!response.ok) {
      this.botStatus = 'error';
    } else {
      const result = await response.json();
      this.botStatus = result.status;
    }
  }
}
</script>
